import { Controller } from "@hotwired/stimulus"
import * as bootstrap from 'bootstrap'

// Connects to data-controller="bs-scrollspy"
export default class extends Controller {
  connect() {
    const target = this.element.getAttribute("data-bs-scrollspy-target")
    const scrollSpy = new bootstrap.ScrollSpy(this.element, {
      target: target
    })
  }
}
