import { Controller } from "@hotwired/stimulus"
import Cookie from "../lib/cookies"

// Connects to data-controller="theme-switcher"
export default class extends Controller {
  COOKIE_NAME = "modeTheme"

  connect() {
  }

  init() {
    const cookieValue = Cookie.get(this.COOKIE_NAME);
    const newThemeValue = cookieValue == "dark" ? "dark" : "light";

    Cookie.set("init modeTheme", newThemeValue);
    window.document.getElementsByTagName("html")[0].setAttribute("data-bs-theme", newThemeValue);

    const icon = this.element.getElementsByTagName("i")[0];
    if (newThemeValue == "dark") {
      icon.classList.remove("bi-sun-fill")
      icon.classList.add("bi-moon-stars-fill")
    } else {
      icon.classList.add("bi-sun-fill")
      icon.classList.remove("bi-moon-stars-fill")
    }
  }

  switch() {
    const cookieValue = Cookie.get(this.COOKIE_NAME);
    const newThemeValue = cookieValue == "dark" ? "light" : "dark";

    Cookie.set("modeTheme", newThemeValue);
    window.document.getElementsByTagName("html")[0].setAttribute("data-bs-theme", newThemeValue);

    const icon = this.element.getElementsByTagName("i")[0];
    if (newThemeValue == "dark") {
      icon.classList.remove("bi-sun-fill")
      icon.classList.add("bi-moon-stars-fill")
    } else {
      icon.classList.add("bi-sun-fill")
      icon.classList.remove("bi-moon-stars-fill")
    }
  }
}
