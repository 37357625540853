import { Controller } from "@hotwired/stimulus"
import { install } from '@github/hotkey'

// Connects to data-controller="hotkey"
export default class extends Controller {
  connect() {
    // Install all the hotkeys on the page
    this.element.addEventListener("turbo:load", this.handleTurboLoad)
    for (const el of this.element.parentNode.querySelectorAll('[data-hotkey]')) {
      console.log(el.dataset)
      install(el)
    }
  }

  handleTurboLoad(event) {
    for (const el of event.getTarget().querySelectorAll('[data-hotkey]')) {
      console.log(el.dataset)
      install(el)
    }
  }
}
