import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"
import { put } from "@rails/request.js";

// Connects to data-controller="sortable"
export default class extends Controller {
  linkedElement = null
  connect() {
    // this.element.style.cursor = "grab"

    if (this.element.dataset["linkedElementId"]) {
      this.linkedElement = document.getElementById(this.element.dataset["linkedElementId"])
    }

    let draggableSelector = ".item"
    if (this.element.dataset["draggableSelector"]) {
      draggableSelector = this.element.dataset["draggableSelector"]
    }
    console.log(draggableSelector, new Sortable(this.element, {
      // group: this.groupValue,
      onEnd: this.onEndFactory(this.linkedElement),
      handle: ".handle",
      // draggable: draggableSelector,
    }))
  }

  onEndFactory(linkedElement) {
    return function (event) {
      const position = event.newIndex + 1
      const url = event.item.dataset["sortableUrl"]
      const formName = event.item.dataset["formName"]
      const positionAttribute = event.item.dataset["positionAttribute"]
      let body = {}
      body[formName] = {}
      body[formName][positionAttribute] = position
      // Expect backend to update list items via turbo if necessary
      put(url, {
        body: JSON.stringify(body),
        contentType: "application/json",
        headers: {
          "Accept": "text/vnd.turbo-stream.html, text/html, application/xhtml+xml"
        }
      })
      if (linkedElement) {
        let children = linkedElement.children
        let child = children[event.oldIndex]
        let newAfter = children[event.newIndex]
        if (event.oldIndex < event.newIndex) {
          newAfter = children[event.newIndex + 1]
        }
        child.parentNode.insertBefore(child, newAfter)
      }
    }
  }
}
