import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"

// Connects to data-controller="toast"
export default class extends Controller {
  connect() {
    const toastBootstrap = bootstrap.Toast.getOrCreateInstance(this.element)
    toastBootstrap.show()
  }
}
