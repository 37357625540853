import { Controller } from "@hotwired/stimulus"

const LEAVE_ALERT = "Es gibt ungespeicherte Änderungen! Wirklich verlassen?"

// Connects to data-controller="unsaved-changes"
export default class extends Controller {
  static targets = ["cancel"]

  initialState = null
  isSubmitted = false

  connect() {
    this.initialState = this.formState()

    window.addEventListener("beforeunload", (event) => this.leavingPage(event))
    document.addEventListener('turbo:before-visit', (e) => this.leavingPage(e))
    this.element.addEventListener("submit", (_) => this.isSubmitted = true)
  }

  formState() {
    return JSON.stringify(Array.from(new FormData(this.element).entries())
      .filter(x => x[1] != "")
      .sort(x => x[0]))
  }

  reset() {
    this.initialState = this.formState()
  }

  hasChanged() {
    return this.formState() != this.initialState
  }

  leavingPage(event) {
    if (this.isSubmitted || !this.hasChanged()) {
      return
    }
    if (!window.confirm(LEAVE_ALERT)) {
      event.preventDefault()
    }
  }
}