import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="check-link"
export default class extends Controller {
  static targets = ["input", "button"]

  connect() {
    this.inputTarget.addEventListener("input", e => this.onUrlInputChange(e))
  }

  onUrlInputChange(event) {
    this.buttonTarget.href = this.inputTarget.value;
    this.buttonTarget.innerHTML = this.inputTarget.value;
    return true;
  }
}
