import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="collapse-chevron-toggler"
export default class extends Controller {
  static targets = ["icon"];
  collapsible = null;

  connect() {
    this.collapsible = window.document.getElementById(this.element.getAttribute('href').substring(1));
    this.setIcon();

    this.collapsible.addEventListener('hide.bs.collapse', e => this.toggle())
    this.collapsible.addEventListener('show.bs.collapse', e => this.toggle())
  }

  setIcon(reverted = false) {
    if (this.collapsible.classList.contains('show')) {
      this.iconTarget.classList.remove('bi-chevron-down')
      this.iconTarget.classList.add('bi-chevron-up')
    } else {
      this.iconTarget.classList.remove('bi-chevron-up')
      this.iconTarget.classList.add('bi-chevron-down')
    }
  }

  toggle() {
    this.iconTarget.classList.toggle('bi-chevron-down');
    this.iconTarget.classList.toggle('bi-chevron-up');
  }
}
