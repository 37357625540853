import { Controller } from "@hotwired/stimulus"
import Cookie from "../lib/cookies"

// Connects to data-controller="set-theme"
export default class extends Controller {
  COOKIE_NAME = "modeTheme";

  connect() {
    console.log("set theme")
    const cookieValue = Cookie.get(this.COOKIE_NAME);
    let darkMode = window.matchMedia("(prefers-color-scheme:dark)").matches ? "dark" : "light";
    if (cookieValue) {
      darkMode = cookieValue
    } else {
      Cookie.set(this.COOKIE_NAME, darkMode);
    }
    window.document.getElementsByTagName("html")[0].setAttribute("data-bs-theme", darkMode)
  }
}
