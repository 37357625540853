import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="details-list"
export default class extends Controller {
  connect() {
  }

  openAll(e) {
    console.log("open all")
    e.preventDefault();
    const id = this.element.dataset["targetId"]
    const el = document.getElementById(id)
    el.querySelectorAll("details").forEach(el => {
      el.setAttribute("open", "")
    })
  }

  closeAll(e) {
    e.preventDefault();
    const id = this.element.dataset["targetId"]
    const el = document.getElementById(id)
    el.querySelectorAll("details").forEach(el => {
      el.removeAttribute("open")
    })
  }
}
