import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="rich-text-link-targets"
export default class extends Controller {
  connect() {
    this.element.querySelectorAll('div.trix-content a').forEach(function(link) {
      if (link.host !== window.location.host) {
        link.target = "_blank"
      }
    })
  }
}
