import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"
import { put } from "@rails/request.js";

// Connects to data-controller="drag"
export default class extends Controller {
  static values = {
    group: String
  }

  connect() {
    this.element.style.cursor = "grab"
    new Sortable(this.element, {
      group: this.groupValue,
      onEnd: this.onEnd,
    })
  }

  onEnd(event) {
    const position = event.newIndex + 1
    const url = event.item.dataset["sortableUrl"]
    // Expect backend to update list items via turbo if necessary
    put(url, {
      body: JSON.stringify({ checklist_entry: { position: position }}),
      contentType: "application/json",
      headers: {
        "Accept": "text/vnd.turbo-stream.html, text/html, application/xhtml+xml"
      }
    })
  }
}
